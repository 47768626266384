/* You can add global styles to this file, and also import other style files */
//@import url(https://maxcdn.bootstrapcdn.com/bootstrap/3.4.0/css/bootstrap.min.css);

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  background: #f4f7fc 0% 0% no-repeat padding-box !important;
}
